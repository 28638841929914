import { createSelector } from 'reselect';

const selectHome = state => state.home;

export const selectCheckStatus = createSelector(
    [selectHome],
    home => home.homeData
);

export const selectSetVehicleNo = createSelector(
    [selectHome], 
    home => home.vehicleNo 
);

export const selectSetDirection = createSelector(
    [selectHome],
    home => home.direction
)