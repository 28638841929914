import React from 'react';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
    constructor() {
        super();

        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        }
    }

    render() {
        if(this.state.hasError) {
            this.props.history.push('/');
            window.location.reload();
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);