import React, { useState, useEffect } from "react";
import {
  selectSetDirection
} from "../../redux/home/home.selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { ReactComponent as Logo } from "../../assets/images/etf-logo.svg";
import VPImage from "../../assets/images/vp.png";
import Ukflag from "../../assets/images/ukflag.png";
import Frflag from "../../assets/images/frflag.png";

import { ImArrowRight } from "react-icons/im";

import "./header.styles.css";

const Header = ({ storedDirection }) => {
  const history = useHistory();
  const [isStatus, setIsStatus] = useState(false);
  
  useEffect(() => {
    const unlisten = history.listen((location) => {
      location.pathname === "/status" ? setIsStatus(true) : setIsStatus(false);
    });
    return function cleanup() {
      unlisten();
    };
  }, [history]);

  return (
    <Container className="header-section mb-4">
      <Row>
        <Col xs="5" md="2">
          <Link to="/" aria-label="Main Logo">
            <Logo />
          </Link>
        </Col>
        <Col xs="3" md="2">
          <img className="vp-image" src={VPImage} alt="VP" />
        </Col>
        {isStatus ? (
          <Col xs="4" md="8">
            <Row className="float-right mt-1">
              <Col xs="1">
                <Col />
                <img className="frpic" src={storedDirection === 'CAFO' ? Frflag : Ukflag} alt="Fr flag" />
              </Col>
              <Col className="arrowright" xs="1">
                <ImArrowRight
                  style={{ height: "17px", width: "40px", marginTop: "22px" }}
                />
              </Col>
              <Col xs="1">
                <img
                  className="ukpic"
                  src={storedDirection !== 'CAFO' ? Frflag : Ukflag}
                  alt="Uk flag"
                  style={{ height: "35px", width: "35px" }}
                />
              </Col>
            </Row>
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  storedDirection: selectSetDirection
});

export default connect(mapStateToProps, null)(Header);
